<template>
  <b-modal
    size="lg"
    id="theModalFarmacia"
    :title="entryData.nombre"
    hide-footer
    @hidden="clearData"
    @hide="preventModalClose"
  >
    <div class="row">
      <div class="col-sm-7">
        <div class="form-group row">
          <div class="col-sm-12">
            <label for="medicamentos"
            class="col-form-label text-right" >Medicamentos:</label >
          </div>
          <div class="col-sm-12">
            <multiselect2
              v-model="productoSelected"
              track-by="id"
              label="nombre"
              :internal-search="false"
              placeholder="Seleccione Producto"
              :options="productos"
              :show-labels="false"
              :custom-label="customLabelProduct"
              @search-change="busquedaAsincronaProductos"
              @input="onInputProductoSelected"
              :disabled="isDisabledInputMedicamentoErp"
            >
              <span slot="caret" v-if="!(productoSelected===null)"></span>
              <span slot="noResult">Sin Resultados</span>
              <span slot="noOptions">Lista Vacia</span>
              <template slot="singleLabel" slot-scope="{ option }">
                  {{ option.nombre }}
              </template>
              <template slot="option" slot-scope="props">
                <span style="color: blue; font-weight: bold">
                [{{ props.option.clase }}]
                </span>
                  -{{ props.option.nombre }}
                <span style="color: red; font-weight: bold">
                  {{ props.option.descripcion }}
                </span>
                  -{{ props.option.etiqueta }}
              </template>
              <template slot="afterList">
                  <div class="row">
                      <a
                      v-if="showCargarMas"
                      href="javascript:void(0)"
                      class="col-sm-12 pl-5 py-1 text-dark font-weight-bold"
                      @click="getListProduct(null)">
                      Cargar mas...
                      </a>
                  </div>
              </template>
            </multiselect2>

          </div>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="form-group row">
          <div class="col-sm-12">
            <label for="unidad"
            class="col-form-label text-right" >Unidad:</label >
          </div>
          <div class="row col-sm-12">
            <div class="col-sm-5">
              <label class="radio" :class="stockSaldoUp > 0 ? 'radio-success': 'radio-danger'">
                <input type="radio" v-model="unidad" :value="true" ref="unidad">
                <span>
                  {{ (productoSelected && productoSelected.unidad_medida) ?
                    productoSelected.unidad_medida.descripcion : ''}}
                </span>
                <span class="checkmark"></span>
              </label>
            </div>
            <div class="col-sm-7">
              <span
                v-if="visualizaStock"
                style="line-height: inherit"
                class="badge badge-pill ml-4"
                :class="stockSaldoUp > 0 ? 'badge-success' : 'badge-danger' "
              >
                Stock: {{stockSaldoUp}}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-2">
        <div class="form-group row">
          <div class="col-sm-12">
            <label for="cantidad"
            class="col-form-label text-right" >Cantidad:</label>
          </div>
          <div class="col-sm-9">
            <input type="text"
              v-model="cantidad"
              @keypress="onlyNumber" @paste.prevent
              class="form-control">
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4" v-if="canAgregarProducto">
        <div class="form-group row">
          <div class="col-sm-12">
            <label for="manual"
              class="col-form-label text-right" >Crear Otros Medicamentos:</label >
          </div>
          <div class="col-sm-12">
            <vue-tags-input
              v-model="medicamentoModel"
              :tags="tagsMedicamentos"
              :autocomplete-items="filteredItemsMedicamentos"
              :max-tags="1"
              @tags-changed="validateTagMedicamentosBeforeSaving"
              :separators="['§§§']"
              class="tag-custom text-15"
              placeholder=""
              :disabled="isDisabledInputMedicamentoClinic"
            />
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group row">
          <div class="col-sm-12">
            <label for="indicaciones"
              class="col-form-label text-right" >Indicaciones:</label >
          </div>
          <div class="col-sm-12">
            <vue-tags-input
              v-model="indicacionModel"
              :tags="tagsIndicacion"
              :autocomplete-items="filteredItemsIndicaciones"
              :max-tags="1"
              @tags-changed="validateTagIndicationBeforeSaving"
              :separators="['§§§']"
              class="tag-custom text-15"
              placeholder=""
            />
          </div>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="form-group row">
          <div class="col-sm-12">
            <label for="via" class="col-form-label text-left">Via</label>
          </div>
          <div class="col-sm-10">
            <multiselect2
              v-model="tipoViaAdministracionSelected"
              track-by="id"
              label="nombre"
              placeholder="Seleccione"
              :options="tipoViaAdministracions"
              :allow-empty="false">
              </multiselect2>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 text-right pr-4 pt-4">
        <button class="btn btnSecondary mr-2" type="button" @click="cancelModal">Cancelar</button>
        <button
          class="btn btnOrange"
          type="button"
          @click="saveModal"
        >Agregar</button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';
import { mapState, mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import Multiselect2 from '@/components/util/vue-multiselect2';

export default {
  name: 'TheModalFarmacia',
  props: {
    entryData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      cantidad: 1,
      delayTimeout: null,
      indicacionModel: '',
      tagsIndicacion: [],
      indicacionesList: [],
      medicamentoModel: '',
      tagsMedicamentos: [],
      medicamentosList: [],
      isDisabledInputMedicamentoClinic: false,
      isDisabledInputMedicamentoErp: false,
      pacienteId: null,
      canCloseModal: false,
    };
  },
  created() {
    const { params } = this.$route;
    this.pacienteId = atob(params.id);
    this.getIndicacionesClinic();
    this.getMedicamentosClinic();
  },
  methods: {
    clearData() {
      this.isDisabledInputMedicamentoClinic = false;
      this.isDisabledInputMedicamentoErp = false;
      this.cantidad = 1;
      this.tipoViaAdministracionSelected = null;
      this.tagsMedicamentos = [];
      this.tagsIndicacion = [];
    },
    preventModalClose(event) {
      if (!this.canCloseModal) {
        event.preventDefault();
      }
    },
    cancelModal() {
      this.canCloseModal = true;
      this.$bvModal.hide('theModalFarmacia');
    },
    onlyNumber(event) {
      const tecla = (document.all) ? event.keyCode : event.which;
      if (tecla < 13) {
        return true;
      }
      if (tecla === 13) {
        return event.preventDefault();
      }
      return this.isNumber(String.fromCharCode(tecla)) ? true : event.preventDefault();
    },
    isNumber(cantidad) {
      const expresion = /^\d+$/;
      return expresion.test(cantidad);
    },
    busquedaAsincronaProductos(query) {
      this.temporaPageComboProducto = 0;
      const DELAY = 400;
      clearTimeout(this.delayTimeout);
      this.delayTimeout = setTimeout(() => this.getListProducto(query), DELAY);
    },
    customLabelProduct({ codigo, nombre, subGrupo }) {
      return `${codigo} - ${nombre} - ${subGrupo}`;
    },
    validateTagMedicamentosBeforeSaving(tags) {
      if (tags.length !== 0) {
        const item = [...tags].pop();
        const longitud = this.medicamentosList.filter((obj) => obj.text === item.text).length;
        if (longitud !== 0) {
          this.tagsMedicamentos = tags;
        } else {
          this.tagsMedicamentos.push({ id: 0, text: item.text });
        }
        this.isDisabledInputMedicamentoErp = true;
        this.productoSelected = null;
      } else {
        this.tagsMedicamentos = [];
        this.isDisabledInputMedicamentoErp = false;
      }
    },
    validateTagIndicationBeforeSaving(tags) {
      if (tags.length !== 0) {
        const item = [...tags].pop();
        const longitud = this.indicacionesList.filter((obj) => obj.text === item.text).length;
        if (longitud !== 0) {
          this.tagsIndicacion = tags;
        } else {
          this.tagsIndicacion.push({ id: 0, text: item.text });
        }
      } else {
        this.tagsIndicacion = [];
      }
    },
    async saveModal() {
      this.guardarReceta();
      this.clearData();
    },
    async guardarReceta() {
      let origenMedicamento = 'ERP';
      let medicamentoId = null;
      let medicamentoNombre = '';

      if (this.isDisabledInputMedicamentoErp) {
        origenMedicamento = 'CLINIC';
        if (this.tagsMedicamentos.length === 0) {
          util.showNotify('Seleccione un medicamento', 'warn');
          return;
        }
        const medicamento = this.tagsMedicamentos[0];
        medicamentoId = medicamento.id;
        medicamentoNombre = medicamento.text;
      } else {
        if (this.productoSelected === null) {
          util.showNotify('Seleccione un medicamento', 'warn');
          return;
        }
        medicamentoId = this.productoSelected.id;
        medicamentoNombre = this.productoSelected.nombre;
      }
      if (this.cantidad === 0) {
        util.showNotify('La cantidad debe ser mayor a 0', 'warn');
        return;
      }
      if (this.tagsIndicacion.length === 0) {
        util.showNotify('Seleccione una indicación', 'warn');
        return;
      }
      if (this.tipoViaAdministracionSelected === null) {
        util.showNotify('Seleccione una via de administración', 'warn');
        return;
      }
      const indicacion = this.tagsIndicacion[0];
      try {
        const REQUEST = {
          medicamento_id: medicamentoId,
          medicamento_nombre: medicamentoNombre,
          medicamento_origen: origenMedicamento,
          cantidad: this.cantidad,
          indicacion_medicamento_id: indicacion.id,
          indicacion: indicacion.text,
          via: this.tipoViaAdministracionSelected.nombre,
          paciente_id: this.pacienteId,
          consulta_id: this.consulta.id,
          estado: true,
        };
        this.$bvModal.hide('theModalFarmacia');
        const RESPONSE = await axios.post('clinic/consultation/patient/receta/store', REQUEST);
        util.showNotify(RESPONSE.data.message, 'success');
        this.getIndicacionesClinic();
        this.getMedicamentosClinic();
        this.$emit('modal-saved', true);
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    formatDataMedicamento(data) {
      const listaTemp = data.map((item) => ({
        id: item.id,
        text: item.nombre,
      }));
      return listaTemp;
    },
    formatDataIndicacion(data) {
      const listaTemp = data.map((item) => ({
        id: item.id,
        text: item.nombre,
      }));
      return listaTemp;
    },
    async getMedicamentosClinic() {
      try {
        const response = await axios.get('/clinic/servicio_complementario/medicamento/list');
        this.medicamentosList = this.formatDataMedicamento(response.data.data);
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    async getIndicacionesClinic() {
      try {
        const response = await axios.get('/clinic/servicio_complementario/indicacion_medicamento/list');
        this.indicacionesList = this.formatDataIndicacion(response.data.data);
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    onInputProductoSelected() {
      if (this.productoSelected !== null) {
        this.isDisabledInputMedicamentoClinic = false;
        this.tagsMedicamentos = [];
        this.getDataProducto();
      } else {
        this.isDisabledInputMedicamentoClinic = false;
        if (this.medicamentoModel !== null) {
          console.log('sin productoselect', this.medicamentoModel);
        }
        this.resetDataModalFarmacia();
      }
    },
    ...mapActions('ConsultaExternaEdit', [
      'getListProducto',
      'getDataProducto',
      'resetDataModalFarmacia',
    ]),
  },
  computed: {
    ...mapState('ConsultaExternaEdit', [
      'consulta',
    ]),
    ...mapFields('ConsultaExternaEdit', [
      'productos',
      'stockSaldoUp',
      'productoSelected',
      'temporaPageComboProducto',
      'totalRegistroProducto',
      'unidad',
      'tipoViaAdministracions',
      'tipoViaAdministracionSelected',
    ]),
    showCargarMas() {
      const isOkToSearch = this.temporaPageComboProducto * 100 < this.totalRegistroProducto;
      return this.totalRegistroProducto > 100 && isOkToSearch;
    },
    filteredItemsIndicaciones() {
      return this.indicacionesList.filter((i) => (
        i.text && this.indicacionModel && i.text.toLowerCase().indexOf(this.indicacionModel.toLowerCase()) !== -1));
    },
    filteredItemsMedicamentos() {
      return this.medicamentosList.filter((i) => (
        i.text.toLowerCase().indexOf(this.medicamentoModel.toLowerCase()) !== -1));
    },
    visualizaStock() {
      if (this.entryData !== null) {
        return (this.entryData.visualiza_stock === 1);
      }
      return false;
    },
    canAgregarProducto() {
      if (this.entryData !== null) {
        return (this.entryData.agrega_producto === 1);
      }
      return false;
    },
  },
  components: {
    Multiselect2,
  },
};
</script>

<style scoped>
.btnOrange {
  color: white;
  background-color: #F4B375;
}
</style>
