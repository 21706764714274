<template>
  <div>
    <b-modal id="modal-signo-vitales" size="lg"
      hide-footer
      title="Enfermeria"
      :header-bg-variant="'gray'"
      :visible="visible"
    >
    <template #modal-header>
        <div class="container-fluid" style="font-size: 12px;">
          <div class="row">
            <div class="col-6 d-flex flex-column">
               <div class="row">
                  <div class="col" v-show="medico">
                    <strong>Médico:</strong> {{medico}}
                  </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div class="row px-3">
          <div class="col-sm-4 pb-3">
            <div class="form-group">
              <label for="talla"><strong>Talla:</strong></label>
              <input type="number" id="talla" class="form-control" v-model="talla" :disabled="por_enfermeria" min="0" step="0.01">
            </div>
            <div class="form-group">
              <label for="imc">
                <strong>IMC</strong> <span class="font-10">(Indice Masa Corporal)</span>
              </label>
              <input
                type="text"
                id="imc"
                class="form-control"
                disabled
                :value="isNaN(imc) ? 'Kg' : imc + ' Kg/m2'"
              >
            </div>
            <div class="form-group">
              <label for="frecuencia_cardiaca">
                <strong>FC</strong> <span class="font-10">(Frecuencia Cardiaca)</span>
              </label>
              <input type="number" id="frecuencia_cardiaca" class="form-control" v-model="frecuenciaCardiaca" :disabled="por_enfermeria" min="0" step="1">
            </div>
          </div>
          <div class="col-sm-4 pb-3">
            <div class="form-group">
              <label for="peso"><strong>Peso:</strong></label>
              <input type="number" id="peso" class="form-control" v-model="peso" :disabled="por_enfermeria" min="0" step="0.1">
            </div>
            <div class="form-group">
              <label for="frecuecia_respiratoria">
                <strong>FR</strong> <span class="font-10">(Frecuencia Respiratoria)</span>
              </label>
              <input type="number" id="frecuecia_respiratoria" class="form-control" v-model="frecuenciaRespiratoria" :disabled="por_enfermeria" min="0" step="1">
            </div>
            <div class="form-group">
              <label for="saturacion_oxigeno">
                <strong>SAT 02</strong> <span class="font-10">(Saturación de Oxigeno)</span>
              </label>
              <input type="number" id="saturacion_oxigeno" class="form-control" v-model="saturacionOxigeno" :disabled="por_enfermeria" min="0" max="100" step="1">
            </div>
          </div>
          <div class="col-sm-4 pb-3">
            <div class="form-group">
              <label for=""><strong>Temperatura:</strong></label>
              <div class="row">
                <div class="col-sm-4 d-flex align-items-center justify-content-end px-0">
                  <label for="oral"><strong>Oral:</strong></label>
                </div>
                <div class="col-sm-8">
                  <input type="number" id="oral" class="form-control" v-model="oral" :disabled="por_enfermeria" min="0" step="0.1">
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="">
                <strong>PA</strong> <span class="font-10">(Presión Arterial)</span>
              </label>
              <div class="row pb-2">
                <div class="col-sm-4 d-flex align-items-center justify-content-end px-0">
                  <label for="sistolica"><strong>Sistolica:</strong></label>
                </div>
                <div class="col-sm-8">
                  <input type="number" id="sistolica" class="form-control" v-model="sistolica" :disabled="por_enfermeria" min="0" step="1">
                </div>
              </div>
              <div class="row">
                <div class="col-sm-4 d-flex align-items-center justify-content-end px-0">
                  <label for="diastolica"><strong>Diastolica:</strong></label>
                </div>
                <div class="col-sm-8">
                  <input type="number" id="diastolica" class="form-control" v-model="diastolica" :disabled="por_enfermeria" min="0" step="1">
                </div>
              </div>
            </div>
          </div>
        </div>
      <div class="row">
        <div class="col-sm-12 text-right">
          <button class="btn btn-outline-dark mr-2" @click="cancelarModal()">
            Cancelar
          </button>
          <button class="btn btn-success" @click="confirmarModal()">
            Guardar
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { round } from 'mathjs';

export default {
  name: 'theModalSignoVitales',
  model: {
    prop: 'visible',
    event: 'change',
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    medico: {
      default: null,
    },
    por_enfermeria: {
      type: Boolean,
    },
  },
  methods: {
    confirmarModal() {
      this.$bvModal.hide('modal-signo-vitales');
      const data = {
        id: this.id,
        talla: parseFloat(this.talla),
        indiceCorporal: parseFloat(this.imc),
        frecuenciaCardiaca: parseFloat(this.frecuenciaCardiaca),
        peso: parseFloat(this.peso),
        frecuenciaRespiratoria: parseFloat(this.frecuenciaRespiratoria),
        saturacionOxigeno: parseFloat(this.saturacionOxigeno),
        oral: parseFloat(this.oral),
        sistolica: parseFloat(this.sistolica),
        diastolica: parseFloat(this.diastolica),
      };
      this.$emit('addSignosVitales', data);
    },
    cancelarModal() {
      this.$bvModal.hide('modal-signo-vitales');
    },
    ...mapActions('theModalSignosVitales', [
      'resetSignosVitales',
    ]),
  },
  computed: {
    // eslint-disable-next-line vue/no-dupe-keys
    imc() {
      let total = 0;
      if (this.peso !== 0 && this.talla !== 0) {
        total = parseFloat(this.peso) / parseFloat(this.talla * this.talla);
      }
      total = round(total, 2);
      return total;
    },
    ...mapFields('theModalSignosVitales', [
      'talla',
      'indiceCorporal',
      'frecuenciaCardiaca',
      'peso',
      'frecuenciaRespiratoria',
      'saturacionOxigeno',
      'oral',
      'sistolica',
      'diastolica',
    ]),
    ...mapState('theModalSignosVitales', [
      'id',
    ]),
  },
  beforeDestroy() {
    this.resetSignosVitales();
  },
};
</script>

<style scoped>
.modal-header {
  display:block !important;
}
.font-10 {
  font-size: 10px;
}
.bg-gray {
  background-color: rgb(200,200,200);
}
</style>
