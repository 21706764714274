<template>
  <div class="col-sm-12">
    <multiselect
      :id="id"
      v-model="diagnosticoSelected"
      :options="listaClasificacionCIEFiltrada"
      open-direction="bottom"
      :internal-search="false"
      :allow-empty="allowEmpty"
      label="subNombre"
      track-by="id_sub"
      :multiple="false"
      select-label="" deselect-label="X"
      placeholder="Seleccione un item"
      @input="searchDiagnosticoAndShow"
      @open="getDataClasificacionPaginate('')"
      @search-change="busquedaAsincronaDiagnosticos"
      class="custom-multiselect"
    >
      <span slot="caret" v-if="!(diagnosticoSelected === null)"></span>
      <span slot="noResult">Sin Resultados</span>
      <span slot="noOptions">Lista Vacía</span>
      <template slot="option" slot-scope="props">
        <span class="option-text">{{ props.option.subCodigo }}  - {{ props.option.subNombre }}</span>
      </template>
      <template slot="afterList">
        <div class="row">
          <a
            v-if="showCargarMas"
            href="javascript:void(0)"
            class="col-sm-12 pl-5 py-1 text-dark font-weight-bold"
            @click="getDataClasificacionPaginate(null)">
            Cargar mas...
          </a>
        </div>
      </template>
    </multiselect>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  components: {
    Multiselect,
  },
  name: 'comboDiagnostic',
  props: {
    id: {
      type: String,
      required: false,
      default: null,
    },
    allowEmpty: {
      type: Boolean,
      default: true,
    },
    diagnosticoInicial: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      diagnosticoSelected: this.diagnosticoInicial,
      delayTimeout: null,
    };
  },
  methods: {
    busquedaAsincronaDiagnosticos(query) {
      this.temporalpageComboClasificacion = 1;
      const DELAY = 400;
      clearTimeout(this.delayTimeout);
      this.delayTimeout = setTimeout(() => this.getDataClasificacionPaginate(query), DELAY);
    },
    searchDiagnosticoAndShow(diagnostico) {
      if (diagnostico === null) {
        this.diagnosticoSelected = null;
        this.$emit('addDiagnostico', null);
        return;
      }
      this.$emit('addDiagnostico', diagnostico);
    },
    async getDataClasificacionPaginate(query) {
      const RESPONSE_OK = await this.obtenerListClasificacion(query);
      if (RESPONSE_OK) {
        this.procesarClasificacionCIE(this.listClasificacionCies);
      }
    },
    procesarClasificacionCIE(responseData) {
      const listaOrganizada = [];

      responseData.forEach((diagnostico) => {
        if (diagnostico.sub_clasificacion_cie && diagnostico.sub_clasificacion_cie.length > 0) {
          diagnostico.sub_clasificacion_cie.forEach((sub) => {
            listaOrganizada.push({
              id: diagnostico.id,
              codigo: diagnostico.codigo,
              nombre: diagnostico.nombre,
              id_sub: sub.id,
              subCodigo: sub.codigo,
              subNombre: sub.nombre,
            });
          });
        } else {
          // Si no hay subclasificaciones, solo agrega el diagnóstico principal
          listaOrganizada.push({
            id: diagnostico.id,
            codigo: diagnostico.codigo,
            nombre: diagnostico.nombre,
            id_sub: null,
            subCodigo: null,
            subNombre: null,
          });
        }
      });

      // this.listaClasificacionCIE = listaOrganizada;
      this.listaClasificacionCIEFiltrada = listaOrganizada;
    },
    ...mapActions('theDiagnostic', [
      'obtenerListClasificacion',
    ]),
    nombreBusqueda({ subCodigo, subNombre }) {
      return `${subCodigo} — [${subNombre}]`;
    },
  },
  watch: {
    diagnosticoInicial: {
      handler(val) {
        this.diagnosticoSelected = val;
      },
      immediate: true,
    },
  },
  computed: {
    ...mapFields('theDiagnostic', [
      'isLoadingComboDiagnosticos',
      'listClasificacionCies',
      'listaClasificacionCIEFiltrada',
      'queryTemporalClasificacion',
      'temporalpageComboClasificacion',
      'totalSearchClasificacion',
    ]),
    showCargarMas() {
      // this.
      const isOkToSearch = this.temporalpageComboClasificacion * 20 < this.totalSearchClasificacion;
      return this.totalSearchClasificacion > 20 && isOkToSearch;
    },
  },
};
</script>

<style>
.custom-multiselect .multiselect__single {
  font-size: 14px;
}

.custom-multiselect .multiselect__option {
  font-size: 14px;
}

.custom-multiselect .multiselect__input {
  font-size: 14px;
}

</style>
